/**
 * Initialize reCAPTCHA if the public sitekey is set.
 */
export default defineNuxtPlugin(() => {
	return {
		provide: {
			loadFormitable,
		},
	};
});

const loadFormitable = () => {
	(function (d, s, id, h) {
		const ftjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) {
			return;
		}
		const js = d.createElement(s);
		js.id = id;
		js.src = 'https://cdn.formitable.com/sdk/v1/ft.sdk.min.js';
		h && (js.onload = h);
		ftjs.parentNode.insertBefore(js, ftjs);
	})(document, 'script', 'formitable-sdk', function () {
		FT.load('Analytics');
	});
};
